import {
    getList,
    exportOrder, //导出
    addCart, updateStatus
} from "./api";
import cancelModal from "./../../channelReturns/distributorReturnList/components/cancelModal";
import Util from "@/utils/utils";
import { cancelDefective } from "@/views/defectiveRetirementNew/hisenseRrturnInput/sale/api";
export default {
    components: {
        cancelModal
    },
    name: 'scaleOrderList',
    data() {
        return {
            breadcrumbData: [{
                path: "/index",
                name: "index",
                title: "首页",
            },
            {
                path: "/hisenseReturn/defectiveReturnListNew",
                name: "",
                title: "残次退明细",
            },
            ],
            navList: [],
            page: {
                pageNo: 1,
                pageSize: 10
            },
            goodsList: [],
            invalidList: [], //批量失效
            insearchOBJ: [],
            paramList: {
                waitData: '',
                isSub:0
            },
            searchzz: false,
            loadings: false,
            // 暂无数据
            NoDate: false,
            nolistImg: require("@/assets/order/noList.png"),
            // 暂无更能多数据
            noMore: false,
            // 查看更多
            loadMores: true,
            goodsNmuber: 0,
            pageLoadFlag: true,
            requestdata: {},
            isLoading: false,
            isSub: 0,// 默认主账户
            onlyZhu: true,
            onlyChid: false,
            openType: '',
            visibleshop: false,
            visiblecancelApply:false,
            salesOrderId:'',
            saleItem:{},
            visibleCancel:false
        }
    },
    beforeCreate() {
        // 添加背景色
        document.querySelector('body').setAttribute('style', 'background-color:#F7F7F7')

    },
    beforeDestroy() {
        window.removeEventListener("storage", function (e) {

        });
        document.querySelector('body').setAttribute('style', '')
    },

    mounted() {
        let that = this;

        // window.addEventListener("storage", function (e) {
        //     // alert(e.newValue);
        //     that.getCommodityList()
        // });
        //根据筛选条件二次请求 不需要初始化调用
        // that.getCommodityList()
    },
    methods: {
        // 取消申请
        openWindowsCancel(salesOrderId,saleItem,type) {
            this.openType = type
            this.visiblecancelApply = true
            this.salesOrderId = salesOrderId
            this.saleItem = saleItem
        },
        openWindows(salesOrderId,saleItem,type) {
            this.openType = type
            this.visibleshop = true
            this.salesOrderId = salesOrderId
            this.saleItem = saleItem
        },
        openWindowsR(salesOrderId,saleItem,type) {
            this.visibleCancel = true
            this.openType = type
            this.salesOrderId = salesOrderId
            this.saleItem = saleItem
        },
        // 驳回窗口确定
        getReason(e) {
            if(!e) {
                this.$message.warning('请输入取消原因')
                return
            }
            let data = {
                defectiveId:this.salesOrderId,
                audit:'驳回',
                auditRemark:e
            }
            this.pageLoadFlag = true
            updateStatus(data).then(res=>{
                this.$message.success(res.data.msg)
                this.pageLoadFlag = false
                this.getCommodityList()
            })
        },
        // 通过窗口确定
        cancelOk() {
            let data = {
                defectiveId:this.salesOrderId,
                audit:'通过',
                auditRemark:''
            }
            this.pageLoadFlag = true
            updateStatus(data).then(res=>{
                this.$message.success(res.data.msg)
                this.pageLoadFlag = false
                this.getCommodityList()
            })
        },
        // 取消申请窗口确定
        withdraw() {
            let data = {
                code: this.saleItem.serialCode
            }
            this.pageLoadFlag = true
            cancelDefective(data.code).then(res=>{
               if(res.data.code == 0){
                   this.$message.success(res.data.msg || res.data.message)
                   this.pageLoadFlag = false
                   this.getCommodityList()
               } else if(res.data.code == 1){
                   this.$message.warning(res.data.msg || res.data.message)
                   this.pageLoadFlag = false
               } else{
                   this.pageLoadFlag = false
               }
            }) .catch(error=>{
                this.pageLoadFlag = false
            })
        },
        // 勾选查看主账户
        changeZhu(e) {
            if (e.target.checked == true) {
                this.paramList.isSub = 0;
                this.onlyZhu = true
                this.onlyChid = false
            } else {
                this.paramList.isSub = 1;
                this.onlyZhu = false
                this.onlyChid = true
            }
            this.page.pageNo = 1
            this.getCommodityList();
        },
        // 勾选查看子账户
        changeZi(e) {
            if (e.target.checked == true) {
                this.paramList.isSub = 1;
                this.onlyChid = true
                this.onlyZhu = false
            } else {
                this.paramList.isSub = 0;
                this.onlyChid = false
                this.onlyZhu = true
            }
            this.page.pageNo = 1
            this.getCommodityList();
        },
        // 勾选查看数据
        onFineshedchange(e) {
            if (e.target.checked == true) {
                this.paramList.waitData = 1;

            } else {
                this.paramList.waitData = "";

            }
            this.page.pageNo = 1
            this.getCommodityList();
        },
        // 加入购物车
        addCart(proid, orgid) {
            let data = { productId: proid, orgId: orgid, num: 1 };
            addCart(data)
                .then((res) => {
                    if (res.data.code == 0) {
                        this.$message.success("加入购物车成功");
                        //更新购物车
                        this.$store.dispatch("user/getCartNum");
                        this.$store.dispatch("user/getCartInfoList");
                    } else {
                        this.$message.warning(res.data.msg);
                    }
                })
                .catch((error) => {
                    this.$message.warning("加入购物车失败");
                });
        },
        //表单筛选
        inputSearch(arg) {
            this.paramList = {
                ...this.paramList,
                ...arg[0]
            };
            this.page.pageNo = 1;
            this.searchzz = true;
            this.pageLoadFlag = true;
            this.getCommodityList();
        },
        // 全选
        chooseAll(e) {
            if (e.target.checked) {
                for (let i = 0; i < this.goodsList.length; i++) {
                    this.goodsList[i].checked = true;
                }
            } else {
                for (let i = 0; i < this.goodsList.length; i++) {
                    this.goodsList[i].checked = false;
                }
            }
            this.$forceUpdate();
        },

        // 导出
        exportClick() {
            let data = {
                ...this.requestdata,
                formCode: 'defectiveList',
            };
            this.isLoading = true;
            exportOrder(data).then(res => {
                this.isLoading = false;
                Util.blobToJson(res.data).then(content => {
                    if (content && content.msg == 'success') {
                        this.$message.success('下载成功，请到下载中心查看!')
                    }
                }).catch(err => {
                    Util.downloadFile(res.data, '残次退列表.xls')
                }).finally(() => {
                    this.expLoading = false;
                })
            })
        },
        downloadFile(params) {
            var blob = params;
            if (
                "download" in document.createElement("a") &&
                navigator.userAgent.indexOf("Edge") == -1
            ) {
                var elink = document.createElement("a");
                elink.download = "残次退列表.xls";
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            } else {
                navigator.msSaveBlob(blob, "残次退列表.xls");
            }
        },
        // 商品选择
        chooseItem(data, index, e) {
            this.goodsList[index].checked = e.target.checked;
            var pushY = this.invalidList.indexOf(data);
            if (pushY == -1) {
                this.invalidList.push(data);
            } else {
                this.invalidList.splice(pushY, 1);
            }
            this.$forceUpdate()
        },

        //查看订单详情
        toDetail(id) {
            let routeUrl = this.$router.resolve({
                path: '/hisenseReturn/hisenseReturnDetailNew',
                query: {
                    id: id,
                }
            });
            window.open(routeUrl.href, "_blank");
        },
        //  发起退货

        gotoReturn() {
            let routeUrl = this.$router.resolve({
                path: '/hisenseReturn/hisenseRrturnInputNew',

            });
            window.open(routeUrl.href, "_blank");
        },

        // 查看历史退残单
        gotoOldPage() {
            let routeUrl = this.$router.resolve({
                path: '/hisenseReturn/defectiveReturnList',

            });
            window.open(routeUrl.href, "_blank");
        },

        //鉴定结果uo
        orderReturn(id) {
            let routeUrl = this.$router.resolve({
                path: '/hisenseReturn/ReturnApplicationDetails',
                query: {
                    id: id,
                }
            });
            window.open(routeUrl.href, "_blank");
        },
        // 查看订单进度
        toProgress(type, id) {
            // if (type == "retail") {
            // this.$router.push({
            //     path: '/retailOrder/progress',
            //     query: {
            //         id
            //     }
            // })
            let routeUrl = this.$router.resolve({
                path: '/hisenseReturn/hisenseReturnDetail',
                query: {
                    id: id,
                }

            });
            window.open(routeUrl.href, "_blank");
            // } else {
            //     this.$router.push({
            //         path: '/channelOrder/progress',
            //         query: {
            //             id
            //         }
            //     })
            // }
        },

        // 商品列表
        getCommodityList() {
            let data = {
                formCode: 'defectiveList',
                pageNo: this.page.pageNo,
                isNew:1,
                ...this.paramList
            }
            this.requestdata = data;
            this.pageLoadFlag = true;
            getList(data).then(res => {
                this.pageLoadFlag = false;
                var realDate = res.data;
                this.goodsNmuber = realDate.totalRows;
                this.loadings = false;
                if (this.searchzz) {
                    this.goodsList = realDate.list;
                }
                if (realDate.list.length == 0 && this.page.pageNo != 1) {

                    this.noMore = true;
                    this.loadMores = false;
                    //没有更多数据
                } else if (realDate.list.length != 0 && this.page.pageNo != 1) {

                    this.goodsList = this.goodsList.concat(realDate.list);
                    this.$forceUpdate();
                } else if (realDate.list.length != 0 && this.page.pageNo == 1) {
                    this.goodsList = realDate.list;

                    this.NoDate = false;
                    this.noMore = false;
                    this.loadMores = true;

                } else if (realDate.list.length == 0 && this.page.pageNo == 1) {
                    this.NoDate = true;
                    this.noMore = false;
                    this.loadMores = false;
                }

                if (realDate.list && realDate.list.length != 0 && this.page.pageNo * this.page.pageSize >= realDate.totalRows) {
                    this.noMore = true;
                    this.loadMores = false;
                }
                for (let i = 0; i < this.goodsList.length; i++) {
                    this.goodsList[i].checked = false;
                }
            })
        },
        // 加载更多
        loadMore() {
            this.loadings = true;
            this.page.pageNo++;
            // this.getCommodityList();
            this.pageLoadFlag = true;
            let data = {
                pageNo: this.page.pageNo,
                ...this.paramList,
                isNew:1,
            }
            getList(data).then(res => {
                this.pageLoadFlag = false;
                var realDate = res.data;
                this.goodsNmuber = realDate.totalRows;
                this.loadings = false;
                this.goodsList = this.goodsList.concat(realDate.list);
                if (realDate.currentPage > realDate.totalPages) {
                    this.noMore = true
                    this.loadMores = false
                }
            })
        }
    }
}